import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  ADD_ENGINE,
  ENGINES_BY_USER,
  TEAMS,
  LOGGED_IN,
} from '../../api/GraphQL';
import Column from '../ui/Column';
import Button from '../ui/form/Button';
import Input from '../ui/form/Input';
import Panel from '../ui/Panel';
import Row from '../ui/Row';

function Create() {
  const history = useHistory();
  const { data: teams } = useQuery(TEAMS);
  const { data: user } = useQuery(LOGGED_IN);
  const [addEngine, { data, loading, error }] = useMutation(ADD_ENGINE, {
    refetchQueries: ['GetEngines'],
    onCompleted: () => {
      window.location.href = '/engines';
    },
  });
  const [name, setName] = useState('');
  const [key, setKey] = useState(uuidv4());
  const [members, setMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  return (
    <Row>
      <Panel title={'Create Engine'} border_title={true}>
        <Column size="12">
          <Row>
            <Column size="4">
              <Input
                id="new_name"
                label={'Name'}
                placeholder={'Name'}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Column>
            <Column size="4">
              <Input
                value={key}
                disabled="true"
                id="new_key"
                label={'Key'}
                placeholder={'Key'}
                type="text"
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column size="4">
              <select
                onChange={(e) => {
                  setSelectedTeam(
                    teams?.teams?.find((u) => u?._id === e.currentTarget.value)
                  );
                }}
              >
                <option selected={!selectedTeam}>Select team</option>
                {teams?.teams
                  ?.filter(
                    (u) => members?.findIndex((m) => m?._id === u._id) === -1
                  )
                  .map((team) => {
                    return (
                      <option
                        value={team?._id}
                        selected={selectedTeam?._id === team?._id}
                      >
                        {team.name}
                      </option>
                    );
                  })}
              </select>
              {selectedTeam && (
                <Button
                  label="Add to team"
                  onClick={() => {
                    setMembers([...members, selectedTeam]);
                    setSelectedTeam();
                  }}
                />
              )}
            </Column>
          </Row>
          <Row>
            {members?.map((member) => {
              return (
                <Row>
                  <Button
                    label="Remove"
                    onClick={() => {
                      let new_members = [...members];
                      new_members = new_members?.filter(
                        (m) => m?._id !== member?._id
                      );
                      setMembers(new_members);
                      setSelectedTeam();
                    }}
                  />
                  {member?.name}{' '}
                </Row>
              );
            })}
          </Row>
          <Row>
            <Column size={12}>
              <Button
                primary
                label={'Create New Engine'}
                onClick={() => {
                  addEngine({
                    variables: {
                      name,
                      key,
                      user: user?.me?._id,
                      teams: members?.map((m) => m?._id),
                    },
                  });
                }}
              />
            </Column>
          </Row>
        </Column>
      </Panel>
    </Row>
  );
}

export default Create;
