import { useQuery } from '@apollo/client';
import React from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router';
import styled from 'styled-components';
import { APIKEY, USER } from '../../api/GraphQL';
import NavigationBar from '../navigation_bar/NavigationBar';
import NavigationBarItem from '../navigation_bar/NavigationBarItem';
import Engines from './tabs/Engines';
import OPTA from './tabs/OPTA';
import Overview from './tabs/Overview';
import Projects from './tabs/Projects';
import Storage from './tabs/Storage';
import Features from './tabs/Features';
import API from './tabs/API';

const Main = styled.div``;
export default function User() {
  const { userid } = useParams();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { loading, data } = useQuery(USER, {
    variables: { id: userid },
    fetchPolicy: 'network-first',
  });

  return (
    <Main>
      {!window.ENV?.REACT_APP_ORGANIZATION &&
        !process.env.REACT_APP_ORGANIZATION && (
          <NavigationBar>
            <NavigationBarItem
              label="Overview"
              active={
                location.pathname.indexOf('projects') === -1 &&
                location.pathname.indexOf('features') === -1 &&
                location.pathname.indexOf('storage') === -1 &&
                location.pathname.indexOf('projects') === -1 &&
                location.pathname.indexOf('engines') === -1 &&
                location.pathname.indexOf('opta') === -1 &&
                location.pathname.indexOf('api') === -1
              }
              onClick={() => {
                history.push(match.url);
              }}
            />
            <NavigationBarItem
              label="Projects"
              active={location.pathname.indexOf('projects') > -1}
              onClick={() => {
                history.push(match.url + '/projects');
              }}
            />
            <NavigationBarItem
              label="Features"
              active={location.pathname.indexOf('features') > -1}
              onClick={() => {
                history.push(match.url + '/features');
              }}
            />
            <NavigationBarItem
              label="Storage"
              active={location.pathname.indexOf('storage') > -1}
              onClick={() => {
                history.push(match.url + '/storage');
              }}
            />
            <NavigationBarItem
              label="Engines"
              active={location.pathname.indexOf('engines') > -1}
              onClick={() => {
                history.push(match.url + '/engines');
              }}
            />
            <NavigationBarItem
              label="OPTA"
              active={location.pathname.indexOf('opta') > -1}
              onClick={() => {
                history.push(match.url + '/opta');
              }}
            />
            <NavigationBarItem
              label="API Keys"
              active={location.pathname.indexOf('api') > -1}
              onClick={() => {
                history.push(match.url + '/api');
              }}
            />
          </NavigationBar>
        )}
      <Switch>
        <Route path={`${match.path}/features`}>
          {data?.user && <Features user={data.user} />}
        </Route>
        <Route path={`${match.path}/projects`}>
          {data?.user && <Projects user={data.user} />}
        </Route>
        <Route path={`${match.path}/storage`}>
          {data?.user && <Storage user={data.user} />}
        </Route>
        <Route path={`${match.path}/engines`}>
          {data?.user && <Engines user={data.user} />}
        </Route>
        <Route path={`${match.path}/opta`}>
          {data?.user && <OPTA user={data.user} />}
        </Route>
        <Route path={`${match.path}/api`}>
          {data?.user && <API user={data.user} />}
        </Route>
        <Route path={`${match.path}`}>
          {data?.user && <Overview user={data.user} />}
        </Route>
      </Switch>
    </Main>
  );
}
