import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router';
import styled from 'styled-components';
import { TEAM, UPDATE_TEAM, USERS } from '../../api/GraphQL';
import NavigationBar from '../navigation_bar/NavigationBar';
import NavigationBarItem from '../navigation_bar/NavigationBarItem';
import Column from '../ui/Column';
import Button from '../ui/form/Button';
import Input from '../ui/form/Input';
import Panel from '../ui/Panel';
import Row from '../ui/Row';
const Main = styled.div``;
export default function Team() {
  const { teamid } = useParams();
  const { data: users } = useQuery(USERS);
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { loading, data } = useQuery(TEAM, { variables: { id: teamid } });
  const [updateTeam, {}] = useMutation(UPDATE_TEAM, {
    refetchQueries: ['GetTeam', 'GetTeams'],
  });
  const [name, setName] = useState('');
  const [autoAddDomain, setAutoAddDomain] = useState('');
  const [members, setMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    setName(data?.teamById?.name);
    setMembers(data?.teamById?.members);
  }, [data]);
  return (
    <Main>
      <Panel title={'Update Team'} border_title={true}>
        <Column size="12">
          <Row>
            <Column size="4">
              <Input
                id="new_name"
                label={'Name'}
                placeholder={'Team Name'}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Column>
          </Row>

          <Row>
            <Column size="4">
              <select
                onChange={(e) => {
                  setSelectedUser(
                    users?.users?.find((u) => u?._id === e.currentTarget.value)
                  );
                }}
              >
                <option selected={!selectedUser}>Select user</option>
                {users?.users
                  ?.filter(
                    (u) => members?.findIndex((m) => m?._id === u._id) === -1
                  )
                  .map((user) => {
                    return (
                      <option
                        value={user?._id}
                        selected={selectedUser?._id === user?._id}
                      >
                        {user.username}
                      </option>
                    );
                  })}
              </select>
              {selectedUser && (
                <Button
                  label="Add to team"
                  onClick={() => {
                    setMembers([...members, selectedUser]);
                    setSelectedUser();
                  }}
                />
              )}
            </Column>
          </Row>
          <Row>
            {members?.map((member) => {
              return (
                <Row>
                  <Button
                    label="Remove"
                    onClick={() => {
                      let new_members = [...members];
                      new_members = new_members?.filter(
                        (m) => m?._id !== member?._id
                      );
                      setMembers(new_members);
                      setSelectedUser();
                    }}
                  />
                  {member?.username}{' '}
                </Row>
              );
            })}
          </Row>
          <Row>
            <Column size={12}>
              <Button
                primary
                label={'Save'}
                onClick={() => {
                  updateTeam({
                    variables: {
                      _id: data.teamById?._id,
                      members: members?.map((m) => m?._id?.toString()),
                      name: name,
                    },
                  });
                }}
              />
            </Column>
          </Row>
        </Column>
      </Panel>
    </Main>
  );
}
