import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router';
import styled from 'styled-components';
import {
  ENGINES_BY_USERS,
  TEAM,
  TEAMS,
  UPDATE_ENGINE,
  USERS,
} from '../../api/GraphQL';
import NavigationBar from '../navigation_bar/NavigationBar';
import NavigationBarItem from '../navigation_bar/NavigationBarItem';
import Column from '../ui/Column';
import Button from '../ui/form/Button';
import Input from '../ui/form/Input';
import Panel from '../ui/Panel';
import Row from '../ui/Row';
const Main = styled.div``;
export default function Engine() {
  const { engineid } = useParams();
  const { data: users } = useQuery(USERS);
  const [updateEngine, {}] = useMutation(UPDATE_ENGINE, {
    refetchQueries: ['GetEngines'],
  });
  const [name, setName] = useState('');
  const [autoAddDomain, setAutoAddDomain] = useState('');
  const [members, setMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const { loading, data: teams } = useQuery(TEAMS);
  const [getEngines, { data: engines }] = useLazyQuery(ENGINES_BY_USERS);
  const [data, setData] = useState();
  useEffect(() => {
    if (users) {
      getEngines({ variables: { ids: users.users.map((u) => u._id) } });
    }
  }, [users, getEngines, engineid]);
  useEffect(() => {
    if (engines) {
      setData(engines.enginesByUsers.find((e) => e._id === engineid));
    }
  }, [engines]);
  useEffect(() => {
    setName(data?.name);

    setMembers(data?.teams);
  }, [data]);
  return (
    <Main>
      <Panel title={'Update Engine'} border_title={true}>
        <Column size="12">
          <Row>
            <Column size="4">
              <Input
                id="new_name"
                label={'Name'}
                placeholder={'Engine Name'}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Column>
          </Row>

          <Row>
            <Column size="4">
              <select
                onChange={(e) => {
                  setSelectedTeam(
                    teams?.teams?.find((u) => u?._id === e.currentTarget.value)
                  );
                }}
              >
                <option selected={!selectedTeam}>Select team</option>
                {teams?.teams
                  ?.filter(
                    (u) => members?.findIndex((m) => m?._id === u._id) === -1
                  )
                  .map((team) => {
                    return (
                      <option
                        value={team?._id}
                        selected={selectedTeam?._id === team?._id}
                      >
                        {team.name}
                      </option>
                    );
                  })}
              </select>
              {selectedTeam && (
                <Button
                  label="Add to team"
                  onClick={() => {
                    setMembers([...members, selectedTeam]);
                    setSelectedTeam();
                  }}
                />
              )}
            </Column>
          </Row>
          <Row>
            {members?.map((member) => {
              return (
                <Row>
                  <Button
                    label="Remove"
                    onClick={() => {
                      let new_members = [...members];
                      new_members = new_members?.filter(
                        (m) => m?._id !== member?._id
                      );
                      setMembers(new_members);
                      setSelectedTeam();
                    }}
                  />
                  {member?.name}{' '}
                </Row>
              );
            })}
          </Row>
          <Row>
            <Column size={12}>
              <Button
                primary
                label={'Save'}
                onClick={() => {
                  updateEngine({
                    variables: {
                      _id: data?._id,
                      teams: members?.map((m) => m?._id?.toString()),
                      name: name,
                    },
                  });
                }}
              />
            </Column>
          </Row>
        </Column>
      </Panel>
    </Main>
  );
}
