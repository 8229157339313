import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { REGISTER_USER } from '../../api/GraphQL';
import Column from '../ui/Column';
import Button from '../ui/form/Button';
import ButtonGroup from '../ui/form/ButtonGroup';
import Input from '../ui/form/Input';
import Panel from '../ui/Panel';
import Row from '../ui/Row';

function Create() {
  const history = useHistory();
  const [register, { data, loading, error }] = useMutation(REGISTER_USER, {
    onCompleted: () => {
      history.push('/users');
    },
    refetchQueries: ['GetUsers'],
  });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [licenseType, setLicenseType] = useState('DEMO');
  return (
    <Row>
      <Panel title={'Create User'} border_title={true}>
        <Column size="12">
          <Row>
            <Column size="4">
              <Input
                id="new_username"
                label={'Username'}
                placeholder={'Username'}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </Column>
            <Column size="4">
              <Input
                id="new_password"
                label={'Password'}
                placeholder={'Password'}
                type="text"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Column>
            <Column size="4">
              <Input
                id="new_email"
                label={'Email'}
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Column>
          </Row>
          {!window.ENV?.REACT_APP_ORGANIZATION &&
            !process.env.REACT_APP_ORGANIZATION && (
              <Row>
                <Column>
                  <ButtonGroup
                    label="License Type"
                    value="DEMO"
                    items={[
                      { label: 'Demo', value: 'DEMO' },
                      { label: 'Free', value: 'FREE' },
                      { label: 'Pro', value: 'PRO' },
                      { label: 'Perpetual', value: 'PERPETUAL' },
                    ]}
                    onChange={(value) => {
                      setLicenseType(value);
                    }}
                  />
                </Column>
              </Row>
            )}
          {!window.ENV?.REACT_APP_ORGANIZATION &&
            !process.env.REACT_APP_ORGANIZATION && (
              <Row>
                <Column>Expires</Column>
                {Date.now()}
              </Row>
            )}
          <Row>
            <Column size={12}>
              <Button
                primary
                label={'Create New User'}
                onClick={() => {
                  register({
                    variables: {
                      username,
                      password,
                      email: email || 'no email',
                      license_type: licenseType?.toUpperCase(),
                      license_expires: Date.now().toString(),
                    },
                  });
                }}
              />
            </Column>
          </Row>
        </Column>
      </Panel>
    </Row>
  );
}

export default Create;
