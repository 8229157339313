import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  gql,
  useQuery,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
const msalConfig = {
  auth: {
    clientId:
      window.ENV?.REACT_APP_MICROSOFT_CLIENT_ID ||
      process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority:
      window.ENV?.REACT_APP_MICROSOFT_AUTHORITY ||
      process.env.REACT_APP_MICROSOFT_AUTHORITY,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPII) => {
        console.log(message);
      },
      logLevel: 'verbose',
    },
  },
};
let pca = null;
if (
  window.ENV?.REACT_APP_MICROSOFT_CLIENT_ID ||
  process.env.REACT_APP_MICROSOFT_CLIENT_ID
) {
  pca = new PublicClientApplication(msalConfig);

  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      pca.setActiveAccount(event.payload.account);
    }
  });
  // Handle the redirect flows
  pca
    .handleRedirectPromise()
    .then((tokenResponse) => {
      // Handle redirect response
    })
    .catch((error) => {
      // Handle redirect error
    });
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + '/graphql',
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Startup msalInstance={pca} />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function Startup({ msalInstance }) {
  return (
    <MSALChecker msalInstance={msalInstance}>
      <App />
    </MSALChecker>
  );
}

function MSALChecker({ msalInstance, children }) {
  if (msalInstance) {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
  } else {
    return <>{children}</>;
  }
}
