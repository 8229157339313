import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Header from './components/header/Header';
import Navigation from './components/navigation/Navigation';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Users from './components/views/Users';
import Teams from './components/views/Teams';
import Login from './components/Authentication/Login';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  gql,
  useQuery,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import { UserContext, UserProvider } from './contexts/UserContext';
import Engines from './components/views/Engines';

const LOGGED_IN = gql`
  query LoggedIn {
    me {
      username
      token
      features {
        name
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 315px;
  width: 100%;
`;
function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <UserProvider>
        <Routes />
      </UserProvider>
    </Router>
  );
}

function Routes() {
  const { loggedIn } = useContext(UserContext);

  return (
    <>
      {!loggedIn && <Login />}
      {loggedIn && (
        <Main>
          <Navigation />
          <Content>
            <Header></Header>
            <Switch>
              <Route path="/users">
                <Users />
              </Route>
              <Route path="/engines">
                <Engines />
              </Route>
              <Route path="/teams">
                <Teams />
              </Route>
              <Route path="/">
                <Users />
              </Route>
            </Switch>
          </Content>
        </Main>
      )}
    </>
  );
}
export default App;
