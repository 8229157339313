import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from '../ui/form/Button';
import Table from '../ui/Table';
import { ENGINES, ENGINES_BY_USERS, USERS } from '../../api/GraphQL';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import CreateEngine from './Create';

const Main = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100%;
  margin-left: -15px;
  padding: 15px;
  flex-direction: column;
`;

export default function List() {
  const { loading, data } = useQuery(USERS, {
    fetchPolicy: 'network-only',
  });
  const [getEngines, { data: engines }] = useLazyQuery(ENGINES_BY_USERS);

  useEffect(() => {
    if (data) {
      getEngines({ variables: { ids: data.users.map((u) => u._id) } });
    }
  }, [data, getEngines]);

  const history = useHistory();
  const match = useRouteMatch();

  return (
    <Main>
      {engines && engines?.enginesByUsers?.length < 10 && (
        <Button
          label="New Engine +"
          onClick={() => {
            history.push(match.url + '/create');
          }}
        />
      )}
      {engines?.enginesByUsers && (
        <Table
          data={engines?.enginesByUsers}
          columns={[
            { Header: 'ID', accessor: '_id' },
            { Header: 'Name', accessor: 'name' },
            {
              Header: 'Key',
              accessor: 'key',
            },
            {
              Header: 'Teams',
              accessor: (item) => {
                return item.teams?.map((t) => t?.name + ',');
              },
            },
          ]}
          onRowSelect={(item) => {
            history.push('/engines/' + item._id);
          }}
        />
      )}
    </Main>
  );
}
