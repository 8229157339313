import gql from 'graphql-tag';

export const USERS = gql`
  query GetUsers {
    users {
      username
      _id
      password
      token
      email
      license_type
      disabled
      last_login
      roles {
        name
      }
    }
  }
`;
export const FEATURES = gql`
  query Features {
    features {
      _id
      name
    }
  }
`;
export const USER = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      username
      _id
      password
      token
      email
      license_type
      project_limit
      disabled
      last_login
      roles {
        name
      }
      features {
        _id
        name
      }
    }
  }
`;
export const REGISTER_USER = gql`
  mutation Mutation(
    $username: String!
    $password: String!
    $email: String!
    $license_type: String!
    $license_expires: String!
  ) {
    register(
      username: $username
      password: $password
      email: $email
      license_type: $license_type
      license_expires: $license_expires
    ) {
      _id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $_id: String!
    $username: String
    $password: String
    $email: String
    $license_type: String
    $license_expires: String
    $project_limit: Int
    $disabled: Boolean
    $features: JSON
  ) {
    updateUser(
      _id: $_id
      username: $username
      password: $password
      email: $email
      license_type: $license_type
      license_expires: $license_expires
      project_limit: $project_limit
      disabled: $disabled
      features: $features
    ) {
      _id
    }
  }
`;
export const PROJECTS = gql`
  query Projects($userId: String!) {
    projectsByUser(userId: $userId) {
      name
      _id
    }
  }
`;
export const ALL_PROJECTS = gql`
  query AllProjects {
    projects {
      name
      _id
      user {
        _id
      }
    }
  }
`;
export const PROJECTS_AND_SCENE = gql`
  query Query($projectId: String!) {
    project(id: $projectId) {
      name
      _id
    }
  }
`;

export const SCENES_IN_PROJECT = gql`
  query Query($project_id: String!) {
    scenesInProject(project_id: $project_id) {
      name
    }
  }
`;

export const ENGINES_BY_USER = gql`
  query Query($id: String!) {
    enginesByUserId(id: $id) {
      _id
      name
      key
      user {
        _id
      }
    }
  }
`;

export const ENGINES = gql`
  query Query {
    engine {
      _id
      name
      key
      user {
        _id
      }
    }
  }
`;
export const ENGINES_BY_USERS = gql`
  query GetEngines($ids: [String!]!) {
    enginesByUsers(ids: $ids) {
      _id
      name
      key
      user {
        _id
      }
      teams {
        _id
        name
      }
    }
  }
`;
export const ADD_ENGINE = gql`
  mutation AddEngine(
    $name: String!
    $key: String!
    $user: String!
    $teams: [String!]
  ) {
    addEngine(name: $name, key: $key, user: $user, teams: $teams) {
      _id
    }
  }
`;
export const TEAMS = gql`
  query Teams {
    teams {
      name
      owner {
        _id
        username
      }
      _id
    }
  }
`;
export const TEAM = gql`
  query GetTeam($id: String!) {
    teamById(id: $id) {
      name
      owner {
        _id
        username
      }
      members {
        _id
        username
      }
      _id
    }
  }
`;
export const ADD_TEAM = gql`
  mutation CreateTeam($name: String!, $owner: String!, $members: [String]) {
    createTeam(name: $name, owner: $owner, members: $members) {
      _id
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation UpdateTeam($_id: String!, $name: String, $members: [String!]) {
    updateTeam(_id: $_id, name: $name, members: $members) {
      _id
    }
  }
`;

export const UPDATE_ENGINE = gql`
  mutation UpdateEngine($_id: String!, $name: String, $teams: [String!]) {
    updateEngine(_id: $_id, name: $name, teams: $teams) {
      _id
    }
  }
`;

export const APIKEY = gql`
  query APIKeys {
    apikeys {
      name
      key
      _id
      user {
        _id
      }
    }
  }
`;
export const APIKEYS_BY_USER = gql`
  query Query($id: String!) {
    apikeysByUserId(id: $id) {
      _id
      name
      key
      user {
        _id
      }
    }
  }
`;
export const ADD_APIKEY = gql`
  mutation AddAPIKey($name: String!, $key: String!, $user: String!) {
    addAPIKey(name: $name, key: $key, user: $user) {
      _id
    }
  }
`;

export const LOGGED_IN = gql`
  query LoggedIn {
    me {
      _id
      username
      token
      roles {
        name
      }
    }
  }
`;
