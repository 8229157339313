import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import HyperLogo from '../../images/HyperLogo.png';
import { UserContext } from '../../contexts/UserContext';

import gql from 'graphql-tag';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useMutation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Button from '../ui/form/Button';
import Input from '../ui/form/Input';
import Row from '../ui/Row';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import MicrosoftButton from './MicrosoftButton';
import { InteractionStatus } from '@azure/msal-browser';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + '/graphql',
});

const user_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      _id
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 368px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
`;

const LogoContainer = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Logo = styled.img`
  height: 50px;
`;
const Form = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 4px 0px rgb(0 0 0 / 16%);
  border-radius: 4px;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
`;
const Message = styled.div`
  display: flex;
  height: 20px;
  padding-left: 0.5em;
`;

const Error = styled.div`
  color: #c11938;
  padding: 1em;
  display: flex;
  justify-content: center;
`;
const InputElement = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 0.4375rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;

  transition: box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  :hover {
    border-color: #b3bdcc;
  }
  :focus {
    border-color: #007bff;
    color: #495057;
    background-color: #fff;
    box-shadow: 0 0.313rem 0.719rem rgb(0 123 255 / 10%),
      0 0.156rem 0.125rem rgb(0 0 0 / 6%);
    outline: none;
  }
  ::placeholder {
    color: #868e96;
  }
`;
export default function Login({ loggedIn }) {
  const { login, signinError } = useContext(UserContext);
  const usernameRef = useRef();
  const passwordRef = useRef();
  const buttonRef = useRef();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = useCallback(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect({
        scopes: ['User.Read'],
      });
    }
  }, [isAuthenticated, inProgress, instance]);

  return (
    <Main>
      <LogoContainer>
        <Logo src={HyperLogo} />
      </LogoContainer>
      {/* <h1>Welcome</h1> */}
      <Form>
        <Row>
          <InputElement
            ref={usernameRef}
            placeholder={'Username'}
            autoComplete="username"
            tabIndex="1"
          ></InputElement>
        </Row>
        <Row>
          <InputElement
            tabIndex="2"
            ref={passwordRef}
            placeholder={'Password'}
            type={'password'}
            autoComplete="current-password"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                login({
                  username: usernameRef.current.value,
                  password: passwordRef.current.value,
                });
              }
            }}
          ></InputElement>
        </Row>
        {signinError && <Error>{signinError}</Error>}
        <Row>
          <Button
            style={{ width: '100%' }}
            primary={true}
            label="Login"
            tabIndex="3"
            ref={buttonRef}
            onClick={() => {
              login({
                username: usernameRef.current.value,
                password: passwordRef.current.value,
              });
            }}
          >
            Login
          </Button>
        </Row>
        {(window.ENV?.REACT_APP_MICROSOFT_CLIENT_ID ||
          process.env.REACT_APP_MICROSOFT_CLIENT_ID) && (
          <MicrosoftButton
            onClick={() => {
              handleLogin();
            }}
          />
        )}
      </Form>
    </Main>
  );
}
